<template>
  <b-row class="main-content-wrapper pt-0 pb-0 mt-0">

    <b-col lg="4" md="6" sm="12" class="mb-5">
      <report-card
        :title="$t(translationPath + 'simulator_electricity')"
        :description="$t(translationPath + 'simulator_electricity_description')"
        :btnCaption="$t(translationPath + 'simulator_electricity_open')"
        :btnUrl="'/simulators/electricity/simple'"
        :imgUrl="require('@/assets/img/simulator-electricity.png')"
        :imgAlt="$t(translationPath + 'simulator_electricity')"
      ></report-card>
    </b-col>

    <b-col lg="4" md="6" sm="12" class="mb-5">
      <report-card
        :title="$t(translationPath + 'mortage_simulator')"
        :description="$t(translationPath + 'mortage_simulator_description')"
        :btnCaption="$t(translationPath + 'mortage_simulator_open')"
        :btnUrl="'/simulators/mortage'"
        :imgUrl="require('@/assets/img/simulator-mortage.png')"
        :imgAlt="$t(translationPath + 'mortage_simulator')"
      ></report-card>
    </b-col>

    <b-col lg="4" md="6" sm="12" class="mb-5">
      <report-card
        :title="$t(translationPath + 'pension_simulator')"
        :description="$t(translationPath + 'pension_simulator_description')"
        :btnCaption="$t(translationPath + 'pension_simulator_open')"
        :btnUrl="'/simulators/pension'"
        :imgUrl="require('@/assets/img/pension-simulator.png')"
        :imgAlt="$t(translationPath + 'pension_simulator')"
      ></report-card>
    </b-col>

    <b-col lg="4" md="6" sm="12" class="mb-5">
      <report-card
        :title="$t(translationPath + 'compounded_interest_simulator')"
        :description="$t(translationPath + 'compounded_interest_simulator_description')"
        :btnCaption="$t(translationPath + 'compounded_interest_simulator_open')"
        :btnUrl="'/simulators/compounded-interest'"
        :imgUrl="require('@/assets/img/compound-interest.png')"
        :imgAlt="$t(translationPath + 'compounded_interest_simulator')"
      ></report-card>
    </b-col>

  </b-row>
</template>

<script>
import ReportCard from '@/components/common/ReportCard'
import titleMixins from '@/mixins/title'

export default {
  name: 'SimulatorReportsTab',
  mixins: [titleMixins],
  components: { ReportCard },
  data () {
    return {
      translationPath: 'reports.simulators._common.'
    }
  },
  computed: {
    titleMeta () {
      return this.$t('reports._common.simulator_reports')
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">

</style>
