<template>
  <b-row class="main-content-wrapper pt-0 pb-0 mt-0">

    <b-col lg="4" md="6" sm="12" class="mb-5">
      <report-card
        :title="$t(translationPath + 'statuscheck_report')"
        :description="$t(translationPath + 'statuscheck_report_description')"
        :btnCaption="$t(translationPath + 'statuscheck_report_open')"
        :btnUrl="'/transactions/view/predefined_selections'"
        :imgUrl="require('@/assets/img/balance-sheet.png')"
        :imgAlt="$t(translationPath + 'statuscheck_report')"
      ></report-card>
    </b-col>

    <b-col lg="4" md="6" sm="12" class="mb-5">
      <report-card
        :title="$t(translationPath + 'account_plan_report')"
        :description="$t(translationPath + 'account_plan_report_description')"
        :btnCaption="$t(translationPath + 'account_plan_report_open')"
        :btnUrl="'/reports/other/account-plan-v2'"
        :imgUrl="require('@/assets/img/account-plan.png')"
        :imgAlt="$t(translationPath + 'account_plan_report')"
      ></report-card>
    </b-col>

  </b-row>
</template>

<script>
import ReportCard from '@/components/common/ReportCard'
import titleMixins from '@/mixins/title'

export default {
  name: 'OtherReportsTab',
  mixins: [titleMixins],
  components: { ReportCard },
  data () {
    return {
      translationPath: 'reports.other._common.'
    }
  },
  computed: {
    titleMeta () {
      return this.$t('reports._common.other_reports')
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>
