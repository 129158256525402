<template>
  <div class="component-performance-report-tabs">

    <b-row no-gutters>
      <b-col lg="8" md="10">
        <h2>{{$t(translationPath+'report_card.title_common')}}</h2>
        <p class="lead mt-0 pt-0">{{$t(translationPath+'report_card.description_common')}}</p>
      </b-col>
    </b-row>

    <b-row class="main-content-wrapper pt-0 pb-0 mt-0">
      <b-col lg="4" md="6" sm="12" class="mb-5">
        <report-card
          :title="$t(translationPath + 'performance_report')"
          :description="$t(translationPath + 'performance_report_description')"
          :btnCaption="$t(translationPath + 'performance_report_open')"
          :btnUrl="'/reports/performance/result/'"
          :imgUrl="require('@/assets/img/performance-report.png')"
          :imgAlt="$t(translationPath + 'performance_report')"
        ></report-card>
      </b-col>

      <b-col lg="4" md="6" sm="12" class="mb-5">
        <report-card
          :title="$t(translationPath + 'performance_report_over_time')"
          :description="$t(translationPath + 'performance_report_over_time_description')"
          :btnCaption="$t(translationPath + 'performance_report_over_time_open')"
          :btnUrl="'/reports/performance/over-time/result'"
          :imgUrl="require('@/assets/img/performance-report-12months.png')"
          :imgAlt="$t(translationPath + 'performance_report_over_time')"
        ></report-card>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col lg="8" md="10">
        <h2>{{$t(translationPath+'report_card.title_special')}}</h2>
        <p class="lead mt-0 pt-0">{{$t(translationPath+'report_card.description_special')}}</p>
      </b-col>
    </b-row>

    <b-row class="main-content-wrapper pt-0 pb-0 mt-0">

      <b-col lg="4" md="6" sm="12" class="mb-5">
        <report-card
          :title="$t(translationPath + 'savings_ratio_report')"
          :description="$t(translationPath + 'savings_ratio_report_description')"
          :btnCaption="$t(translationPath + 'savings_ratio_report_open')"
          :btnUrl="'/reports/performance/savings-ratio/'"
          :imgUrl="require('@/assets/img/savings-ratio.png')"
          :imgAlt="$t(translationPath + 'savings_ratio_report')"
        ></report-card>
      </b-col>

      <b-col lg="4" md="6" sm="12" class="mb-5">
        <report-card
          :title="$t(translationPath + 'cashflow_quadrant_title')"
          :description="$t(translationPath + 'cashflow_quadrant_description')"
          :btnCaption="$t(translationPath + 'cashflow_quadrant_open')"
          :btnUrl="'/reports/performance/cashflow-quadrant'"
          :imgUrl="require('@/assets/img/cashflow-quadrant-asfi.png')"
          :imgAlt="$t(translationPath + 'cashflow_quadrant_title')"
        ></report-card>
      </b-col>

      <b-col lg="4" md="6" sm="12" class="mb-5">
        <report-card
          :title="$t(translationPath + 'active_passive_report')"
          :description="$t(translationPath + 'active_passive_report_description')"
          :btnCaption="$t(translationPath + 'active_passive_report_open')"
          :btnUrl="'/reports/performance/active-passive/'"
          :imgUrl="require('@/assets/img/reports-active-passive.png')"
          :imgAlt="$t(translationPath + 'active_passive_report')"
        ></report-card>
      </b-col>

      <b-col lg="4" md="6" sm="12" class="mb-5">
        <report-card
          :title="$t(translationPath + 'financial_freedom_report')"
          :description="$t(translationPath + 'financial_freedom_report_description')"
          :btnCaption="$t(translationPath + 'financial_freedom_report_open')"
          :btnUrl="'/reports/performance/financial-freedom'"
          :imgUrl="require('@/assets/img/financial-freedom.png')"
          :imgAlt="$t(translationPath + 'financial_freedom_report')"
        ></report-card>
      </b-col>

      <b-col lg="4" md="6" sm="12" class="mb-5">
        <report-card
          :title="$t(translationPath + 'financial_security_report')"
          :description="$t(translationPath + 'financial_security_report_description')"
          :btnCaption="$t(translationPath + 'financial_security_report_open')"
          :btnUrl="'/reports/performance/financial-security'"
          :imgUrl="require('@/assets/img/financial-security.png')"
          :imgAlt="$t(translationPath + 'financial_security_report')"
        ></report-card>
      </b-col>

      <b-col lg="4" md="6" sm="12" class="mb-5">
        <report-card
          :title="$t(translationPath + 'split_expenses_report')"
          :description="$t(translationPath + 'split_expenses_report_description')"
          :btnCaption="$t(translationPath + 'split_expenses_report_open')"
          :btnUrl="'/reports/performance/split-expenses'"
          :imgUrl="require('@/assets/img/report-split-expenses.png')"
          :imgAlt="$t(translationPath + 'split_expenses_report')"
        ></report-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import ReportCard from '@/components/common/ReportCard'
import titleMixins from '@/mixins/title'

export default {
  name: 'PerformanceReportsTab',
  mixins: [titleMixins],
  components: { ReportCard },
  data () {
    return {
      translationPath: 'reports.performance._common.'
    }
  },
  computed: {
    titleMeta () {
      return this.$t('reports._common.performance_reports')
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-balance-report-tabs{}
</style>
