<template>
  <b-row>
    <b-col lg="4" md="6" sm="12">
      <report-card
        :title="$t(translationPath + 'budget_period_report_title')"
        :description="$t(translationPath + 'budget_period_report_description')"
        :btnCaption="$t(translationPath + 'budget_period_report_open')"
        :btnUrl="'/reports/budget/period-report/period'"
        :imgUrl="require('@/assets/img/budget-outcome.png')"
        :imgAlt="$t(translationPath + 'budget_period_report_title')"
      ></report-card>
    </b-col>

    <b-col lg="4" md="6" sm="12">
      <report-card
        :title="$t(translationPath + 'performance_budget')"
        :description="$t(translationPath + 'performance_budget_description')"
        :btnCaption="$t(translationPath + 'performance_budget_open')"
        :btnUrl="'/reports/budget/performance/summary'"
        :imgUrl="require('@/assets/img/budget-report.png')"
        :imgAlt="$t(translationPath + 'performance_budget')"
      ></report-card>
    </b-col>

    <b-col lg="4" md="6" sm="12">
      <report-card
        :title="$t(translationPath + 'long_term_performance_report')"
        :description="$t(translationPath + 'long_term_performance_report_description')"
        :btnCaption="$t(translationPath + 'long_term_performance_report_open')"
        :btnUrl="'/reports/budget/lifeplan/'"
        :imgUrl="require('@/assets/img/long-term-budget.png')"
        :imgAlt="$t(translationPath + 'split_expenses_report')"
      ></report-card>
    </b-col>

  </b-row>
</template>

<script>
import ReportCard from '@/components/common/ReportCard'
import titleMixins from '@/mixins/title'

export default {
  name: 'BudgetReportsTab',
  mixins: [titleMixins],
  components: { ReportCard },
  data () {
    return {
      translationPath: 'reports.budget._common.'
    }
  },
  computed: {
    titleMeta () {
      return this.$t('reports._common.budget_reports')
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">

</style>
