<template>
  <b-container class="reports overview bg-light page-wrapper">
    <b-row class="main-content-wrapper" no-gutters>
      <b-col>
        <reports-submenu-header :active="activeTab" @tab-switched="displayTab"></reports-submenu-header>
        <div class="pt-2">
          <performance-reports-tab v-if="activeTab === 'performanceReports'"></performance-reports-tab>
          <balance-reports-tab v-if="activeTab === 'balanceReports'"></balance-reports-tab>
          <budget-reports-tab v-if="activeTab === 'budgetReports'"></budget-reports-tab>
          <other-reports-tab v-if="activeTab === 'otherReports'"></other-reports-tab>
          <simulator-reports-tab v-if="activeTab === 'simulatorReports'"></simulator-reports-tab>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SimulatorReportsTab from './_inc/report-tabs/SimulatorReportsTab'
import ReportsSubmenuHeader from './_inc/report-tabs/ReportsSubmenuHeader'
import BalanceReportsTab from './_inc/report-tabs/BalanceReportsTab'
import OtherReportsTab from './_inc/report-tabs/OtherReportsTab'
import BudgetReportsTab from './_inc/report-tabs/BudgetReportsTab'
import PerformanceReportsTab from './_inc/report-tabs/PerformanceReportsTab'

export default {
  name: 'AllReports',
  props: ['reportType'],
  data () {
    return {
      activeTab: 'resultReports'
    }
  },
  methods: {
    displayTab (tab) {
      switch (tab) {
        case 'balance':
          this.activeTab = 'balanceReports'
          break
        case 'other':
          this.activeTab = 'otherReports'
          break
        case 'simulator':
          this.activeTab = 'simulatorReports'
          break
        case 'budget':
          this.activeTab = 'budgetReports'
          break
        default:
          this.activeTab = 'performanceReports'
      }
    }
  },
  watch: {
    'reportType' () {
      this.displayTab(this.reportType)
    }
  },
  created () {
    this.displayTab(this.reportType)
  },
  components: {
    PerformanceReportsTab,
    BudgetReportsTab,
    ReportsSubmenuHeader,
    BalanceReportsTab,
    OtherReportsTab,
    SimulatorReportsTab
  }
}
</script>

<style lang="scss" scoped>
.main-content-wrapper {
    width: 100%;
}
</style>
