<template>
  <div class="component-balance-report-tabs">

    <b-row no-gutters>
      <b-col lg="8" md="10">
        <h2>{{$t(translationPath+'report_card.title_common')}}</h2>
        <p class="lead mt-0 pt-0">{{$t(translationPath+'report_card.description_common')}}</p>
      </b-col>
    </b-row>

    <b-row class="main-content-wrapper pt-0 pb-0 mt-0">
      <b-col lg="4" md="6" sm="12" class="mb-5">
        <report-card
          :title="$t(translationPath + 'balance_report')"
          :description="$t(translationPath + 'balance_report_description')"
          :btnCaption="$t(translationPath + 'balance_report_open')"
          :btnUrl="'/reports/balance/report'"
          :imgUrl="require('@/assets/img/balance-sheet.png')"
          :imgAlt="$t(translationPath + 'balance_report')"
        ></report-card>
      </b-col>

      <b-col lg="4" md="6" sm="12" class="mb-5">
        <report-card
          :title="$t(translationPath + 'balance_report_over_time')"
          :description="$t(translationPath + 'balance_report_over_time_description')"
          :btnCaption="$t(translationPath + 'balance_report_over_time_open')"
          :btnUrl="'/reports/balance/over-time-report/'"
          :imgUrl="require('@/assets/img/balance-report-over-time.png')"
          :imgAlt="$t(translationPath + 'balance_report_over_time')"
        ></report-card>
      </b-col>
    </b-row>

    <b-row no-gutters>
      <b-col lg="8" md="10">
        <h2>{{$t(translationPath+'report_card.title_special')}}</h2>
        <p class="lead mt-0 pt-0">{{$t(translationPath+'report_card.description_special')}}</p>
      </b-col>
    </b-row>

    <b-row class="main-content-wrapper pt-0 pb-0 mt-0">
      <b-col lg="4" md="6" sm="12" class="mb-5">
        <report-card
          :title="$t(translationPath + 'risk_scale_report_title')"
          :description="$t(translationPath + 'risk_scale_report_description')"
          :btnCaption="$t(translationPath + 'risk_scale_report_open')"
          :imgUrl="require('@/assets/img/risk-scale.png')"
          :btnUrl="'/reports/balance/risk-scale/'"
        ></report-card>
      </b-col>

      <b-col lg="4" md="6" sm="12" class="mb-5">
        <report-card
          :title="$t(translationPath + 'productive_asset_report_title')"
          :description="$t(translationPath + 'productive_asset_report_description')"
          :btnCaption="$t(translationPath + 'productive_asset_report_open')"
          :imgUrl="require('@/assets/img/productive-non-productive.png')"
          :btnUrl="'/reports/balance/productive-assets/'"
        ></report-card>
      </b-col>

      <b-col lg="4" md="6" sm="12" class="mb-5">
        <report-card
          :title="$t(translationPath + 'free_restricted_capital_report_title')"
          :description="$t(translationPath + 'free_restricted_capital_report_description')"
          :btnCaption="$t(translationPath + 'free_restricted_capital_report_open')"
          :imgUrl="require('@/assets/img/free-restricted.png')"
          :btnUrl="'/reports/balance/free-restricted-capital/'"
        ></report-card>
      </b-col>

      <b-col lg="4" md="6" sm="12" class="mb-5">
        <report-card
          :title="$t(translationPath + 'four_bucket_report')"
          :description="$t(translationPath + 'four_bucket_report_description')"
          :btnCaption="$t(translationPath + 'four_bucket_report_open')"
          :btnUrl="'/reports/balance/four-buckets/'"
          :imgUrl="require('@/assets/img/four-bucket.png')"
          :imgAlt="$t(translationPath + 'four_bucket_report')"
        ></report-card>
      </b-col>

      <b-col lg="4" md="6" sm="12" class="mb-5">
        <report-card
          :title="$t(translationPath + 'subtypes_report')"
          :description="$t(translationPath + 'subtypes_report_description')"
          :btnCaption="$t(translationPath + 'subtypes_report_open')"
          :btnUrl="'/reports/balance/subtypes/'"
          :imgUrl="require('@/assets/img/balance-subtypes.png')"
          :imgAlt="$t(translationPath + 'subtypes_report')"
        ></report-card>
      </b-col>

      <b-col lg="4" md="6" sm="12" class="mb-5">
        <report-card
          :title="$t(translationPath + 'asset_allocation_report')"
          :description="$t(translationPath + 'asset_allocation_report_description')"
          :btnCaption="$t(translationPath + 'asset_allocation_report_open')"
          :imgUrl="require('@/assets/img/balance-asset-classes.png')"
          :btnUrl="'/reports/balance/asset-allocation/'"
        ></report-card>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import titleMixins from '@/mixins/title'
import ReportCard from '@/components/common/ReportCard'

export default {
  name: 'BalanceReportsTab',
  mixins: [titleMixins],
  components: { ReportCard },
  data () {
    return {
      translationPath: 'reports.balance._common.'
    }
  },
  computed: {
    titleMeta () {
      return this.$t('reports._common.balance_reports')
    }
  },
  watch: {
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
.component-balance-report-tabs{}
</style>
